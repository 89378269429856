var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-12 xc-card pa-4",attrs:{"width":"100%","max-width":"400","min-height":"455"}},[_c('div',{staticClass:"mt-4",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"text-h5 text-center mb-4"},[_vm._v(" "+_vm._s(_vm.$t("string.completed"))+" ")]),_c('v-sheet',{attrs:{"height":"100","color":"transparent"}},[(_vm.showshield)?_c('v-img',{staticClass:"mx-auto zoom-in",attrs:{"contain":"","max-width":"100","src":require(_vm.star == 3
            ? '@/assets/math/shields/gradeA.png'
            : _vm.star == 2
            ? '@/assets/math/shields/gradeB.png'
            : _vm.star == 1
            ? '@/assets/math/shields/gradeC.png'
            : '@/assets/math/shields/gradeF.png')}}):_vm._e()],1),_c('v-sheet',{attrs:{"height":"80","color":"transparent"}},[(_vm.showstars)?_c('div',{staticClass:"d-flex justify-center fade-in"},[_c('div',{staticClass:"mx-2"},[_c('v-icon',{attrs:{"color":_vm.totalStars > 0 ? 'amber darken-1' : 'gray lighten-2',"x-large":""}},[_vm._v(" mdi-star")]),_c('div',{staticClass:"text-center text-h6"},[_vm._v("3")])],1),_c('div',{staticClass:"mx-2"},[_c('v-icon',{attrs:{"color":_vm.totalStars > 1 ? 'amber darken-1' : 'gray lighten-2',"x-large":""}},[_vm._v(" mdi-star")]),_c('div',{staticClass:"text-center text-h6"},[_vm._v("4")])],1),_c('div',{staticClass:"mx-2"},[_c('v-icon',{attrs:{"color":_vm.totalStars > 2 ? 'amber darken-1' : 'gray lighten-2',"x-large":""}},[_vm._v(" mdi-star")]),_c('div',{staticClass:"text-center text-h6"},[_vm._v("5")])],1)]):_vm._e()]),(_vm.showComplete)?_c('div',{staticClass:"d-flex justify-center text-h6 align-center zoom-in-x"},[_c('v-icon',{staticClass:"me-2",attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]),_vm._v(" "+_vm._s(_vm.countCorrect)+" / "+_vm._s(_vm.totalQ)+" ")],1):_vm._e(),(_vm.showBtn)?_c('div',{staticClass:"d-flex flex-column align-center mt-6 fade-in"},[_c('v-btn',{staticClass:"my-2",attrs:{"color":"green","max-width":"200","width":"100%","rounded":"","dark":""},on:{"click":_vm.clickDone}},[_vm._v(" "+_vm._s(_vm.isDemo ? "SIGN UP" : _vm.$t("action.done"))+" ")]),_c('v-btn',{staticClass:"my-2",attrs:{"color":"secondary","outlined":"","max-width":"200","width":"100%","rounded":""},on:{"click":_vm.clickReplay}},[_vm._v(" "+_vm._s(_vm.$t("action.playAgain"))+" ")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }